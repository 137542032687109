import './App.css';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"

const notify = (meg) => toast.info(meg);
const errors = (meg) => toast.error(meg);
function App() {
  
  const [data, setData] = useState();
  const [address, setAddress] = useState("")

  async function getUserIp() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
     
      console.error('Error fetching IP address:', error);
      return null;
    }
  }

  useEffect(() => {

  }, []);

  const claimtoken = async(event)=>{
    try {
      event.preventDefault();
      const ip = await getUserIp()
      notify("request has been sent, please wait.")
      axios.post(`/faucet/claim/${address}/${ip}`).then(response => {
        setData(response.data.hash);
      }).catch(error => {
        errors(error?.response?.data)
        console.error('Error fetching data:', error?.response?.data);
      });
    } catch (error) {
      
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>
          QuestChain Faucet
        </p>
        <div>
      <form onSubmit={claimtoken}>
        <div>
          <input
            type="text"
            required={true}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter address"
          />
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <p className='hash'>{data ? `transaction submited. Hash: ${data}` : ''}</p>
    </div>
      </header>
      <ToastContainer />
    </div>
  );
}

export default App;
